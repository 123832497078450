import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Snackbar, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import { format, differenceInCalendarDays, isAfter } from 'date-fns';
import useCookie from './hooks/use_cookie';
import { safeParse } from '@dt/functions';

import { useSession } from '@dt/ahura/src/session';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  snackbar: {
    width: '80%',
  },
});

export default (function TemporaryBroadcastMessage() {
  const classes = useStyles();
  const { loading: sessionLoading, data: sessionData } = useSession();
  let accountName = '';

  if (!sessionLoading && sessionData) {
    accountName = sessionData?.user_account.accountInfo.name ?? '';
  }

  const BROADCAST_MESSAGE = {
    message: accountName
      ? `All ${accountName} iOS & Android apps, including all binaries with and without a subscription, have been tested for the OpenSSL Critical Vulnerability CVE-2022-3602/CVE-2022-3786 and NONE of them are vulnerable. Have a great rest of your day.`
      : `All iOS & Android apps, including all binaries with and without a subscription, have been tested for the OpenSSL Critical Vulnerability CVE-2022-3602/CVE-2022-3786 and NONE of them are vulnerable. Have a great rest of your day.`,
    expires: '2022-11-16',
    hash: 'c7fa6ff47492e011a686218193275810',
  };

  const [dismissed, setDismissed] = useState(false);

  const expiryDate = useMemo(() => {
    return format(new Date(BROADCAST_MESSAGE.expires));
  }, [BROADCAST_MESSAGE.expires]);
  const daysToExpiry = useMemo(() => {
    return differenceInCalendarDays(new Date(expiryDate), new Date());
  }, [expiryDate]);

  const cookieKey = useMemo(() => {
    return BROADCAST_MESSAGE.hash ? `DT-${BROADCAST_MESSAGE.hash}` : '';
  }, [BROADCAST_MESSAGE.hash]);

  const [broadcastCookie, setBroadcastCookie] = useCookie(cookieKey);

  // No existing cookie for the current broadcast message
  useEffect(() => {
    if (typeof broadcastCookie === 'undefined') {
      const cookieValue = JSON.stringify({
        dismissed: false,
        expires: expiryDate,
      });

      daysToExpiry > 0
        ? setBroadcastCookie(cookieKey, cookieValue, { expires: daysToExpiry })
        : setBroadcastCookie(cookieKey, cookieValue);
    }
  }, [broadcastCookie, cookieKey, BROADCAST_MESSAGE.expires, expiryDate, setBroadcastCookie, daysToExpiry]);

  const broadcastCookieJSON = useMemo(() => {
    return safeParse(broadcastCookie);
  }, [broadcastCookie]);

  const dismissedOrExpiredCookie = useMemo(() => {
    return broadcastCookieJSON
      ? broadcastCookieJSON.dismissed || isAfter(new Date(), broadcastCookieJSON.expires)
      : false;
  }, [broadcastCookieJSON]);

  const handleDismissBroadcastMessage = useCallback(
    (event                               , reason         ) => {
      if (reason === 'clickaway') {
        return;
      }

      setDismissed(true);

      // Update the cookie to reflect that the message should be permanently dismissed
      const cookieValue = JSON.stringify({
        dismissed: true,
        expires: broadcastCookie ? JSON.parse(broadcastCookie)['expires'] : 0,
      });

      setBroadcastCookie(cookieKey, cookieValue, {
        expires: daysToExpiry > 0 ? daysToExpiry : 0,
      });
    },
    [cookieKey, setBroadcastCookie, broadcastCookie, daysToExpiry],
  );

  if (dismissed || dismissedOrExpiredCookie || daysToExpiry <= 0) {
    return null;
  }

  if (!BROADCAST_MESSAGE.message) {
    return null;
  }

  return (
    <Snackbar
      className={classes.snackbar}
      open={!dismissed}
      onClose={handleDismissBroadcastMessage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        severity="success"
        variant="standard"
        onClose={handleDismissBroadcastMessage}
        action={
          <Grid container direction={'row'} justifyContent={'flex-end'}>
            <Button
              aria-label={'Dismiss'}
              variant="outlined"
              size={'small'}
              color="primary"
              onClick={handleDismissBroadcastMessage}
            >
              Awesome
            </Button>
          </Grid>
        }
      >
        {BROADCAST_MESSAGE.message}
      </Alert>
    </Snackbar>
  );
});
