var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';
import { Drawer, Grid } from '@mui/material';
// @ts-ignore
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore TODO-IMPLEMENT-APOLLO-LINK-SCHEMA-REST
import { useQuery } from '@dt/apollo-link-schema-rest';
// @ts-ignore TODO-IMPLEMENT-TYPES
import horizon_notifications from '@dt/graphql-support/horizon/notification_events';
// @ts-ignore TODO-IMPLEMENT-TYPES
import sevenhell_notifications from '@dt/graphql-support/sevenhell/portal_notifications';
import { NotificationItem, NotificationItemError, NotificationItemLoadingSkeleton, NotificationItemZeroState, // @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
 } from '@dt/material-components/notifications';
import SlackLogo from '@dt/material-components/svg/slack_logo.svg';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
import { differenceInDays, format } from 'date-fns';
var NotificationsDrawer = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var isOpen = _a.isOpen, toggle = _a.toggle;
    var _h = useQuery(horizon_notifications.list), horizonNotificationsData = _h.data, horizonNotificationsLoading = _h.loading, horizonNotificationsError = _h.error, horizonNotificationsFetchMore = _h.fetchMore;
    var _j = useQuery(sevenhell_notifications.list), sevenhellNotificationsData = _j.data, sevenhellNotificationsLoading = _j.loading, sevenhellNotificationsError = _j.error, sevenhellNotificationsFetchMore = _j.fetchMore;
    var horizonNotifications = ((_b = horizonNotificationsData === null || horizonNotificationsData === void 0 ? void 0 : horizonNotificationsData.notification_events_list) === null || _b === void 0 ? void 0 : _b.events)
        ? horizonNotificationsData.notification_events_list.events
        : [];
    var sevenhellNotifications = ((_c = sevenhellNotificationsData === null || sevenhellNotificationsData === void 0 ? void 0 : sevenhellNotificationsData.portal_notifications_list) === null || _c === void 0 ? void 0 : _c.portal_notifications)
        ? sevenhellNotificationsData.portal_notifications_list.portal_notifications
        : [];
    var notificationsLoading = sevenhellNotificationsLoading || horizonNotificationsLoading;
    var notificationsError = sevenhellNotificationsError || horizonNotificationsError;
    var currentDate = new Date(Date.now());
    var hasTodayBeenChecked = false;
    if (horizonNotificationsError) {
        console.error('horizonNotificationsError:', horizonNotificationsError);
    }
    if (sevenhellNotificationsError) {
        console.error('sevenhellNotificationsError:', sevenhellNotificationsError);
    }
    var notifications = useMemo(function () {
        return __spreadArray(__spreadArray([], horizonNotifications, true), sevenhellNotifications, true).sort(function (a, b) {
            if (a.date_created && b.date_created) {
                if (a.date_created > b.date_created) {
                    return -1;
                }
                return 1;
            }
            return 0;
        });
    }, [horizonNotifications, sevenhellNotifications]);
    return (React.createElement(Drawer, { open: isOpen, onClose: toggle, anchor: "right", style: { zIndex: 1100 }, PaperProps: {
            style: {
                width: 488 + 72,
                overflowX: 'hidden',
            },
        }, "aria-labelledby": 'Notifications Drawer' },
        React.createElement(Grid, { container: true, direction: "column", spacing: 2, alignItems: "flex-start", style: { padding: 16 } },
            React.createElement(Grid, { item: true },
                React.createElement("div", { style: { margin: '8px 0', display: 'flex', alignItems: 'center' } },
                    React.createElement(Text, { variant: "titleS", style: { marginRight: 4 }, component: "span" }, "Alerts"),
                    React.createElement(Text, { variant: "body", component: "span" },
                        "(contact ",
                        React.createElement("a", { href: "mailto:support@datatheorem.com" }, "support@datatheorem.com"),
                        " to join the Slack Channel"),
                    React.createElement("img", { src: SlackLogo, height: 18, width: 18, alt: "Slack Icon", style: { marginLeft: 4, marginRight: 4 } }),
                    React.createElement(Text, { variant: "body", component: "span" }, ")"))),
            !notifications && !notificationsLoading && !notificationsError && React.createElement(NotificationItemZeroState, null),
            notifications
                ? notifications.map(function (notification) {
                    var dateHeader = null;
                    if (differenceInDays(Date.now(), notification.date_created) === 0 && !hasTodayBeenChecked) {
                        dateHeader = 'Today';
                        hasTodayBeenChecked = true;
                    }
                    if (differenceInDays(currentDate, notification.date_created) > 0) {
                        dateHeader = format(notification.date_created, 'MMM dd yyyy');
                        currentDate = notification.date_created;
                    }
                    return (React.createElement(Grid, { key: notification.id, item: true },
                        Boolean(dateHeader) && (React.createElement(Text, { variant: "titleXS", style: { margin: 0, color: palette.gray35 } }, dateHeader)),
                        React.createElement(NotificationItem, { notification: notification })));
                })
                : null,
            notificationsLoading &&
                [0, 1, 2, 3, 4, 5, 6].map(function (i) {
                    return (React.createElement(Grid, { key: i, item: true },
                        React.createElement(NotificationItemLoadingSkeleton, null)));
                }),
            !sevenhellNotificationsLoading &&
                ((_e = (_d = sevenhellNotificationsData === null || sevenhellNotificationsData === void 0 ? void 0 : sevenhellNotificationsData.portal_notifications_list) === null || _d === void 0 ? void 0 : _d.pagination_information) === null || _e === void 0 ? void 0 : _e.next_cursor) && (React.createElement(Grid, { item: true },
                React.createElement(Waypoint, { onEnter: function () {
                        sevenhellNotificationsFetchMore && sevenhellNotificationsFetchMore();
                    } }))),
            !horizonNotificationsLoading &&
                ((_g = (_f = horizonNotificationsData === null || horizonNotificationsData === void 0 ? void 0 : horizonNotificationsData.notification_events_list) === null || _f === void 0 ? void 0 : _f.pagination_information) === null || _g === void 0 ? void 0 : _g.next_cursor) && (React.createElement(Grid, { item: true },
                React.createElement(Waypoint, { onEnter: function () {
                        horizonNotificationsFetchMore && horizonNotificationsFetchMore();
                    } }))),
            (horizonNotificationsError || sevenhellNotificationsError) && React.createElement(NotificationItemError, null))));
};
export default NotificationsDrawer;
